import React from 'react';
import './card.css';

const Card = (props) => {
  return (
    <div id='card'>
            <div id='mask'>
            <span id='textactivite'>{props.textact}</span>
            </div>
            <div id='imgill'>
               <img src={props.imgsrc} alt="photoillustr" />
            </div>
    </div>
  )
}

export default Card