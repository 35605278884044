import React,{useState} from 'react'
import './Articles.css'
import MiniDevis from '../../component/mini_devis/mini_devis'
import donnee_img from '../../databaseimg'
import donnee_texte from '../../databasetexte'
import Article from '../../component/Article/Article'
import facebook from '../../assets/articles/image 5.png'
import twitter from '../../assets/articles/image 6.png'
import Confirmation from '../../component/confirmation/Confirmation'

import img1 from '../../assets/Helios/Img1.jpg'
import img2 from '../../assets/Helios/Img2.jpg'
import img3 from '../../assets/Helios/Img3.jpg'
import img4 from '../../assets/Helios/Img4.jpg'
import img5 from '../../assets/Helios/Img5.jpg'


const Articles = (props) => {
  const [Hide_Ok,setHide_Ok]=useState('Hide')

  const okpopup=(e)=>{
    
    setHide_Ok('block')

  }
 

  const ok_hide=(e)=>{
    setHide_Ok('Hide')
    
  }
  return (


    <>
      <div style={{position :'fixed',top:'20vh',left:'35vw'}}>
        <Confirmation Id={Hide_Ok} onClick={ok_hide}/>
      </div>

      <div id='Articles' onLoad={props.onLoad}>
      <div id='Articles_content'>
        <div id='Artillustr'>
         <img id='Artillustrimg' src={img4} alt="article illustration"/>
         <div id='Aref'>
          <span id="date">Lundi 01 Janvier 2024 - 09:30</span>
          <div id='rs'>
            <img src={facebook} alt="facebook" />
            <img src={twitter} alt="twitter" />
          </div>
         </div>
        </div>
        
        <h1>Déménagement des bureaux de la Société Helios Tower Congo</h1>

        <div>
          <p>
          En guise de coup d'envoi de l'année 2024, IBS s'est vu confié par son partenaire Helios Tower Congo, la gestion du déménagement de l'ensemble de son mobilier et autres matériels pour leurs nouveaux bureaux.
          </p>
    
        </div>

      </div>
      <MiniDevis okpopup={okpopup}/>
      </div>

      <div className='visuel'>
        <div className='vitem1'><img src={img1} alt="img1" /></div>
        <div className='vitem2'><img src={img2} alt="img2" /></div>
        <div className='vitem5'><img src={img5} alt="img5" /></div>
        <div className='vitem3'><img src={img3} alt="img3" /></div>
        <div className='vitem4'><img src={img4} alt="img4" /></div>
      </div>

      <div id='Interventions' >
        <div id='Interventions_center' className="Akati">
          <h1> 
            Nos Dernières réalisations 
          </h1>
          <p>
            Ici un condensé des dernières réalisations d’IBS, témoignage de notre efficacité réactivité
          </p>
          <div className="flexrow">
            <Article to='../Articles' id='art1' AIS={donnee_img.image2} paragraphArticle={donnee_texte.Articles.article2}/>
            <Article to='../Articles' id='art2' AIS={donnee_img.image1} paragraphArticle={donnee_texte.Articles.article1}/>
            <Article to='../Articles' id='art3' AIS={donnee_img.image3} paragraphArticle={donnee_texte.Articles.article3}/>
          </div>
        </div>      
      </div>

    </>
    
  )
}

export default Articles