import React from 'react'
import './Confirmation.css'
import facebook from '../../assets/follow/fb.png'
import linkedin from '../../assets/follow/in.png'
import twitter from '../../assets/follow/t.png'

const Confirmation = (props) => {

  return (
    <div id={props.Id} className='position'>
        <div id='boite'>
            <div id='follow'>
                <div id='imgfollow'>
                    <img src={facebook} alt="logo_facebook"/>
                    <img src={linkedin} alt="logo_linkedin"/>
                    <img src={twitter} alt="logo_twitter"/>
                </div>
                <span>Nous suivre</span>
            </div>
            
            <span>Merci ! Message a bien été envoyé</span>

            <button id='Ok' onClick={props.onClick}>OK</button>
        </div>
    </div>
    
  )
}

export default Confirmation