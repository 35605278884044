import React from 'react'
import './Article.css'
import {Link} from 'react-router-dom'

const Article = (props) => {
  return (
    <>
      <Link id='articlelink' to={props.to}>
        <div id={props.id} className="contenaire">
            <img src={props.AIS} alt="Illustrtext"/>
            <p className="paragraph">
              {props.paragraphArticle}
            </p>
            <div>
              <div id='lirelasuite'> <span id='lire'>Lire la suite</span> <span id='arrow'> &rarr;</span></div> 
            </div>
          
        </div>
      </Link>

        
    </>
    
  )
}

export default Article