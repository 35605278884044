import React from 'react'
import './ML.css'

const ML = () => {
  return (
    <>
    <div id='ML'>
      <div className='Centercontent'>
        <h1>Mentions légales</h1>
      
        <h2> Interlogistique Batiment Services</h2>
 
        <h2>Raison sociale :</h2>

        <div>
          Interlogistique Batiment Services (IBS) <br/>
          S.A.R.L au capital de 1 000 000 FCFA <br/>
          <span>RCCM : </span> C.G PNR.RCCM. 07 A 057 <br/>
          <span>NIU : </span> M2014110001679147
        </div>

        <h2> Siège social:</h2>
        <div>
          1416 Avenue Loutassi Q plateau <br/>
          Brazzaville République du Congo <br/>
          Avenue de l’indépendance cinq chemins Q Mpaka  <br/>
          Pointe-Noire République du Congo <br/>
          <span>Email : </span> interlog2007f@yahoo.fr | <span>Tél.</span> +242 05 300 98 10 /05 307 82 82
        </div>
      </div>
    </div>
    </>
  )
}

export default ML