/*Texte carre */
const transport='Transportez vos marchandises, colis et courriers, de manière simple et efficace. Ayant pour objectif la satisfaction de ses clients, IBS vous accompagne durant tout le processus, tout en optimisant le coût et les délais.'

const fret='Pour être performants au quotidien dans le cadre de leurs activités, nos partenaires ( particuliers, entreprises, Administrations, ONG … ) ont besoin d’une structure de fret expérimentée pour la préparation, l’expédition et la réception de leurs marchandises et matériels. C’est dans cet esprit de service global que nous nous appliquons à respecter nos engagements.'

const demenagement='IBS se démarque par son expertise qui garantit le succès de votre déménagement. Peu importe le lieu de départ et de destination, nous vous accompagnons et vous garantissons un déménagement sécurisé et sans stress.'

const logistique ='Ayant une approche intégrée de la logistique de proximité, nous apportons une sérénité totale à nos partenaires dans la gestion d’entrepôts et services associés.'

const livraison_express='Avec IBS, la livraison express n’a plus de secret pour vous. Expédiez et recevez vos colis et courriers dans toutes les villes et villages du Congo en temps réel.'

const textcarre={transport,fret,demenagement,logistique,livraison_express}

/*texte card*/


/*texte articles*/
const article2='Opération de montage des étapes métalliques et fabrication dépot...'
const article1='Opération de déchargement de groupe électrogène pour le...'
const article3='Gestion de dépôt et stockage assurée par les agents d’IBS '
const article4='Déménagement des bureaux de la Société Helios Tower Congo '
const Articles ={article1,article2,article3,article4}

const donnee_texte ={textcarre,Articles}


export default donnee_texte