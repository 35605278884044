import React,{useState} from 'react'
import './Articles.css'
import MiniDevis from '../../component/mini_devis/mini_devis'
import donnee_img from '../../databaseimg'
import donnee_texte from '../../databasetexte'
import Article from '../../component/Article/Article'
import facebook from '../../assets/articles/image 5.png'
import twitter from '../../assets/articles/image 6.png'
import Confirmation from '../../component/confirmation/Confirmation'


const Articles = (props) => {
  const [Hide_Ok,setHide_Ok]=useState('Hide')

  const okpopup=(e)=>{
    
    setHide_Ok('block')

  }
 

  const ok_hide=(e)=>{
    setHide_Ok('Hide')
    
  }
  return (


    <><div style={{position :'fixed',top:'20vh',left:'35vw'}}>
        <Confirmation Id={Hide_Ok} onClick={ok_hide}/>
    </div>
      <div id='Articles' onLoad={props.onLoad}>
      <div id='Articles_content'>
        <div id='Artillustr'>
         <img id='Artillustrimg' src={donnee_img.image2} alt="article illustration"/>
         <div id='Aref'>
          <span id="date">Mercredi 20 Juillet 2022 - 18:15</span>
          <div id='rs'>
            <img src={facebook} alt="facebook" />
            <img src={twitter} alt="twitter" />
          </div>
         </div>
        </div>
        
        <h1>Opération de montage des étapes métalliques<br/> et fabrication dépot pour le compte de Airtel <br/> Congo</h1>

        <div>
          <p>
            Cette opération à été réalisée avec professionalisme et minutie par une equipe IBS de 5 agents, déployés sur site pendant environ deux semaines.
          </p>
          <p>         
            Ces derniers ce sont attelés à mettre sur pied des étapes métalliques destinés à faciliter un usage efficient et efficace de l’entrepôt de stockage.
          </p>
        </div>

      </div>
      <MiniDevis okpopup={okpopup}/>
      </div>
      <div id='Interventions' >
        <div id='Interventions_center' className="Akati">
          <h1> 
            Nos Dernières réalisations 
          </h1>
          <p>
            Ici un condensé des dernières réalisations d’IBS, témoignage de notre efficacité réactivité
          </p>
          <div className="flexrow">
            <Article to='../ArticleHelios' id='art4' AIS={donnee_img.image4} paragraphArticle={donnee_texte.Articles.article4}/>
            <Article to='../Articles' id='art1' AIS={donnee_img.image2} paragraphArticle={donnee_texte.Articles.article2}/>
            <Article to='../Articles' id='art2' AIS={donnee_img.image1} paragraphArticle={donnee_texte.Articles.article1}/>
            <Article to='../Articles' id='art3' AIS={donnee_img.image3} paragraphArticle={donnee_texte.Articles.article3}/>
          </div>
        </div>      
       </div>
    </>
    
  )
}

export default Articles