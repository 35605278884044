import emailjs from '@emailjs/browser';


export const sendEmaildevisform = (serviceId,templateId,formulaire,Clepublic) => {
    
    emailjs.sendForm(serviceId, templateId, formulaire,Clepublic)
      .then((result) => {
          //console.log(result.text);
          //alert(result.text);
      }, (error) => {
          //console.log(error.text);
          //alert(error.text);
      });
  };
  export const sendEmailform = (e,serviceId,templateId,formulaire,Clepublic) => {
    
    emailjs.sendForm(serviceId, templateId, formulaire,Clepublic)
      .then((result) => {
          console.log(result.text);
          alert(result.text);
      }, (error) => {
          console.log(error.text);
          alert(error.text);
      });
  };

  export const sendEmail = (e,serviceId,templateId,params,Clepublic) => {
    
    emailjs.send(serviceId, templateId, params, Clepublic)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

 // export function popovertest(e) { alert(HTMLElement.prototype.hasOwnProperty("popover"))}