import React from 'react'
import './Navpol.css'
import {NavLink} from 'react-router-dom'
import logo_blanc from "../../assets/logofooter.png"
import hamburger from '../../assets/mobile/hamburger.png'
import phone_ico from '../../assets/mobile/phone_ico.png'


const Navpol =(props) => {
  
  return (
    <>
    <div  className='globalnav'onClick={props.Anchor}>
      <div id='navcustom'>
      <a href='./' onClick={props.NavTogfunc}><img src={logo_blanc} alt="logoblanc" /></a>
      <ul id='navul'>
        <li>
          <NavLink to='./CGU' style={({isActive})=>{return isActive?{color: 'white', backgroundColor:'#002363',fontWeight: 700,transition: '0.3s'}:{}}}>CGU</NavLink>
        </li>
        <li>
          <NavLink to='./DPC' style={({isActive})=>{return isActive?{color: 'white', backgroundColor:'#002363',fontWeight: 700,transition: '0.3s'}:{}}}>DPC</NavLink>
        </li>
        <li>
          <NavLink to='./ML' style={({isActive})=>{return isActive?{color: 'white', backgroundColor:'#002363',fontWeight: 700,transition: '0.3s'}:{}}}>ML</NavLink> 
        </li>
      </ul>
      <div id='anumb'>
          <div id='numb'>
            <a href="tel:+242 06 707 82 82"><span id='numbfirstchild'> 06 707 82 82</span></a>
            <a href="tel:+242 05 399 89 37"><span id='numbfirstchild'> 05 399 89 37</span></a>
            <span id='numbsecondchild'>7j/7 A  Votre service</span>
          </div>
      </div>
      <div id='mobilenav' onClick={(e)=>e.currentTarget.nextSibling.classList.toggle('hide')}>
                    <div id='ham'><img src={hamburger} alt="hamburger" /></div>
                    
      </div> 
      <div className='pol menuderoulant hide'  onClick={props.Anchor}>                 
        <ul id='polNav'>
            <li><NavLink to='./CGU' style={({isActive})=>{return isActive?{color: 'white',fontSize:'1rem',fontWeight: 700,transition: '0.3s',backgroundColor:'#002e62'}:{}}}>CGU</NavLink></li>
            <li><NavLink to='./DPC' style={({isActive})=>{return isActive?{color: 'white',fontSize:'1rem',fontWeight: 700,transition: '0.3s',backgroundColor:'#002e62'}:{}}}>DPC</NavLink></li>
            <li><NavLink to='./ML' style={({isActive})=>{return isActive?{color: 'white',fontSize:'1rem',fontWeight: 700,transition: '0.3s',backgroundColor:'#002e62'}:{}}}>ML</NavLink></li>
        </ul>
        <div id='bloc_call' >
          <a href="tel:+242 06 707 82 82" id='numb_to_call'>
            <span id='zip'><img src={phone_ico} alt="call" /> +242 </span>
            <span id='central_part'>06 707 82 82</span>
          </a>
          <a href="tel:+242 05 399 89 37" id='numb_to_call'>
            <span id='zip'><img src={phone_ico} alt="call" /> +242 </span>
            <span id='central_part'>05 399 89 37</span>
          </a>
           
          <span >7j/7 A  Votre service</span>
        </div>
                        
      </div> 
    </div>
    
    </div>
    </>
  )
}

export default Navpol