import React from 'react'
import './About.css'
import Membre from '../../component/Membre/Membre'
import donneeimg from '../../databaseimg'
import dame from '../../assets/Martine.png'
/*profils start*/
import venance from '../../assets/profil/Venance.png'
import fatou from '../../assets/profil/Fatou.png'
import nazaire from '../../assets/profil/nazaire.png'
import owama from '../../assets/profil/owama.png'
import Ngoma from '../../assets/profil/Ngoma.png'
import lewo from '../../assets/profil/Lewo.png'
import mabouana from '../../assets/profil/mabouana.png'
import Gabin from '../../assets/profil/Gabin.png'
import Rich from '../../assets/profil/Rich.png'
import Ruth from '../../assets/profil/Ruth.png'

/*profils end*/



const About = () => {
  return (
    <div id='About'>

      <div id='Aboutsection1'>
        <div id='sect1textg'>
          <p>
          " C’est dans le marécage que pousse la fleur de Lotus."
          </p>
          <p>
          " La persévérance est un guide infaillible qui mène au succès..."
          </p>
          <span>Nous suivre</span>
          <div id='nosreseaux'> 
            <img src={donneeimg.facebook} alt="facebook" />
            <img src={donneeimg.Linkedin} alt="LinkedIn" />
            <img src={donneeimg.twitter} alt="Twitter" />
          </div>
          
        </div>
        
        <div id='accroche'>
          <h1> 
             MARTINE IBOUNA <br/>
            <span>DIRECTRICE GÉNÉRALE</span>
          </h1>
        </div>
        <div id="dame">
          <img src={dame} alt="dame" />
        </div>
      </div>

      <div id='Aboutsection2'>
        <h1 className='Centercontent'>Notre histoire</h1>
        <p className='Centercontent'>
        Interlogistique Batiment et Services (IBS) voit son origine dans l’esprit de Madame Ibouna Martine (Directrice Générale) avant de prendre vie et corps à Brazzaville, dans une chambre de son domicile au cours de l’an 2014.  
        </p>
        <p className='Centercontent'>
        Véritable aboutissement d’une vie professionelle (Plus de 20 ans de carrière) passée au service de diverses entreprises privées, IBS vient sanctionner de part sa naissance la volonté de sa Directrice Générale de s’affranchir dans le monde professionnel.
        </p>
        <p className='Centercontent'>
        9 années de travail acharné sont passées au cours desquelles ce qui fut pensé comme une entreprise personnelle se métamorphosa en une formidable aventure humaine et sociétale. 
        C’est à la force du poignet et au prix d’une détermination sans faille de son leadership, que se tient aujourd’hui fièrement debout la PME n° 1  nationale du transport, de la logistique de proximité et du service express. 
        </p>
      </div>

      <div id='Aboutsection2plus'>
        <h1>Nos Certifications</h1>
        <div>
          <p>
          Société de droit congolais, Interlogistique Batiments et Services (IBS) est aggrée par l'Agence de Régulation des Postes et des Communication Electroniques par la décision N°090/ARPCE-DG/DAJI/DRP/17 du 23 Octobre 2017.
          </p>
        </div>
        <div>

          <p>
          Pour sécuriser aussi bien son activité que ses clients, IBS est couvert par une police d'assurance de RESPONSABILITE CIVILE et PROFESSIONNELLE souscrite sous le numéro A1.05.006/16 aux ASSURANCES GENERALES DU CONGO (AGC) couvrant jusqu'à 100 000 000 XAF
          </p>

          <p>
          Ces garanties font de IBS une entreprise crédible et responsable vis-à-vis de ses partenaires et des institutions gage de la confiance bilatérale qui nous est accordée.
          </p>
        </div>
        
      </div>

      <div id='Aboutsection3'>
      <h1 className='Centercontent'>L'equipe IBS</h1>
        <h2 className='Centercontent'>Les professionels de l’express!</h2>
        <p className='Centercontent'>
        "Se réunir est un début, rester ensemble est un progrès, travailler ensemble est une réussite."
        </p>
      </div>

      <div id='Aboutsection4' className='Centercontent'>
        <div className="chef">
          <Membre profil={venance} poste='DIRECTEUR GÉNÉRAL ADJOINT
' noms='M. Orfèvre Venance Pila - Pila '/>
        </div>
        <div className="subordonnee">
          <Membre profil={fatou} poste='ASSISTANTE COMPTABLE & FINANCIERE
' noms='Mlle. Fatou Ndiaye '/>
          <Membre profil={Gabin} poste='RESPONSABLE LOGISTIQUE' noms='M. Gabin Mouitys '/>
          <Membre profil={Ngoma} poste='ASSITANTE EXÉCUTIVE' noms='Mlle Ngoma Emissere'/>
        </div>
        <div className="subordonnee">
          <Membre profil={owama} poste='AGENT D’EXPLOITATION
' noms='Mr Owoma Régis'/>
          <Membre profil={lewo} poste='AGENT D’EXPLOITATION

' noms='Mr Lewo Jocsys'/>
          <Membre profil={nazaire} poste='AGENT D’EXPLOITATION
' noms='Mr Kaboulou Nazaire'/>
        </div>
        <div className="subordonnee">
          <Membre profil={Rich} poste='AGENT D’EXPLOITATION
' noms='Mr Bakalé Rich '/>
          <Membre profil={Ruth} poste='AGENT COMMERCIAL' noms='Kombo Ruth'/>
          <Membre profil={mabouana} poste='COURSIER' noms='Mr Mabouana Clay'/>
        </div>

      </div>

  
    </div>

  )
}

export default About