

//Regex defintion
const Regex_Nom= new RegExp(/^[a-z A-Z]{3,10}?$/)
const Regex_Prenom= new RegExp(/^[a-z A-Z]{3,10}?$/)
const Regex_Tel= new RegExp(/^\++[0-9]{6,14}?$/)
// real one const Regex_Tel= new RegExp(/^[0-9]{6,12}?$/)
//const Regex_Tel= new RegExp(/^\++[0-9]{6,14}?$/)
const Regex_Mail= new RegExp(/^[a-z A-Z 0-9.-_]+@[a-zA-Z 0-9.-_]+\.[a-z]{2,6}?$/)

const erreurs={ nom_erreur:'Le champ Nom est mal rempli',
                       prenom_erreur:'Le champ Prénom est mal rempli',
                       numero_erreur:' Le champ Tél. est mal rempli ',
                       mail_erreur : 'Le champ mail est mal rempli',
                       message_erreur:' Le message ne peut-être vide'
}


export function checker_Nom_Utils(form,setMessage_erreur,set_bool_erreur){  
    if(Regex_Nom.test(form.current.childNodes[0].value))
        {   
           setMessage_erreur('')              
           form.current.childNodes[0].classList.remove('failed')
           set_bool_erreur(true)
                          
        }
    else
        {   
                    
            setMessage_erreur(erreurs.nom_erreur);
            form.current.childNodes[0].classList.add('failed')
            set_bool_erreur(false)
                            
        }
}
export function checker_Prenom_Utils(form,setMessage_erreur,set_bool_erreur){  
    if(Regex_Prenom.test(form.current.childNodes[1].value))
    {
        setMessage_erreur('')
        form.current.childNodes[1].classList.remove('failed')
        set_bool_erreur(true)
        
    }

    else
    {
      
        setMessage_erreur(erreurs.prenom_erreur);
        form.current.childNodes[1].classList.add('failed')
        set_bool_erreur(false)
        
    }
}
export function checker_Tel_Utils(form,setMessage_erreur,set_bool_erreur){ 
    if(Regex_Tel.test(form.current.childNodes[2].value)) 
    {
        setMessage_erreur('')
        form.current.childNodes[2].classList.remove('failed')
        set_bool_erreur(true)
        
    }

    else 
    {

        setMessage_erreur(erreurs.numero_erreur);
        form.current.childNodes[2].classList.add('failed')
        set_bool_erreur(false)
        
    }
} 
export function checker_Mail_Utils(form,setMessage_erreur,set_bool_erreur){ 
    if(Regex_Mail.test(form.current.childNodes[3].value)) 
    {
        setMessage_erreur('')
        form.current.childNodes[3].classList.remove('failed')
        set_bool_erreur(true)
        
    }

    else 
    {
      
        setMessage_erreur(erreurs.mail_erreur);
        form.current.childNodes[3].classList.add('failed')
        set_bool_erreur(false)
        
        
    }
}
export function checker_Message_Utils(e,setMessage_erreur,set_bool_erreur){  
        if(e.target.value.length<=0)
        {
           setMessage_erreur(erreurs.message_erreur);
           e.target.classList.add('failed')
           set_bool_erreur(false)
        }

        else
        {
            
          setMessage_erreur('')
          e.target.classList.remove('failed')
          set_bool_erreur(true)
            
        }
}


export function mini_checker_Nom_Utils(form,setMessage_erreur,set_bool_erreur){  
    if(Regex_Nom.test(form.current.childNodes[0].value)){
        setMessage_erreur('');
        form.current.childNodes[0].classList.replace('erreur', 'inputsmini')
        set_bool_erreur(true)
    }
    else {
        setMessage_erreur('champ nom mal saisi');
        form.current.childNodes[0].classList.replace('inputsmini', 'erreur')
        set_bool_erreur(false)
    }
}
export function mini_checker_Prenom_Utils(form,setMessage_erreur,set_bool_erreur){  
    if(Regex_Prenom.test(form.current.childNodes[1].value)) {
        setMessage_erreur('');
        form.current.childNodes[1].classList.replace('erreur', 'inputsmini')
        set_bool_erreur(true)
    }

    else  {
        setMessage_erreur('champ prénom mal saisi');
        form.current.childNodes[1].classList.replace('inputsmini', 'erreur')
        set_bool_erreur(false)
    }
}
export function mini_checker_Tel_Utils(form,setMessage_erreur,set_bool_erreur){ 
    if(Regex_Tel.test(form.current.childNodes[2].value)) {
        setMessage_erreur('');
        form.current.childNodes[2].classList.replace('erreur', 'inputsmini')
        set_bool_erreur(true)
    }

    else {
        setMessage_erreur(' Champ tel mal saisi');
        form.current.childNodes[2].classList.replace('inputsmini', 'erreur')
        set_bool_erreur(false)
    }
} 
export function mini_checker_Mail_Utils(form,setMessage_erreur,set_bool_erreur){ 
    if(Regex_Mail.test(form.current.childNodes[3].value)) {
        setMessage_erreur('');form.current.childNodes[3].classList.replace('erreur', 'inputsmini')
        set_bool_erreur(true)
    }

    else {
        setMessage_erreur('champ mail mal saisi');
        form.current.childNodes[3].classList.replace('inputsmini', 'erreur')
        set_bool_erreur(false)
    }
}
export function mini_checker_Message_Utils(e,setMessage_erreur,set_bool_erreur){  
    if(e.target.value.length<=0)
    {
        setMessage_erreur(erreurs.message_erreur);
        e.target.classList.replace('inputsmini','erreur')
        set_bool_erreur(false)
    }

    else
    {
      setMessage_erreur('')
      e.target.classList.replace( 'erreur','inputsmini');set_bool_erreur(true)
      
    }
}

