import React from 'react'
import './encard.css'


const Encard = (props) => {
  return (
    <>
    <div id="encard">

        <div><img src={props.img} alt="ico" /></div>

        <h1>{props.titre}</h1>

        <p>
            {props.paragraph}
        </p>
        
    </div>
    </>
  )
}

export default Encard