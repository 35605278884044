import React from 'react';
import './button.css'

const Button = (props) => {
  
  return (
    <>
   
    <div id={props.id} className='buttondevis' onClick={props.onClick}>
        {props.text} 
    </div>
    </>
    
  )
}

export default Button