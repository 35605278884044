import React from 'react'
import './footer.css'
import logoblanc from '../../assets/logofooter.png'
import linkedin from '../../assets/reseaux/linkedin.png'
import twitter  from '../../assets/reseaux/twitter.png'
import facebook  from '../../assets/reseaux/facebook.png'
import { NavLink } from 'react-router-dom'


const Footer = (props) => {
  return (
    <>
      <div id='footerstart'>
          <div id='footerstart_center'>
            <div id='societe'>
                <img src={logoblanc} alt="Logo_blanc" />
                <p>
                Nous garantissons 
                votre satisfaction.
                </p>
                <div id='reseaux'>
                  <img src={linkedin} alt="Linkendin" />
                  <img src={twitter} alt="twitter" />
                  <img src={facebook} alt="facebook" />
                </div>
            </div>
            <div id='s9services'> 
                <span id='services'>Nos services</span>
                <ul>
                  <li>Transport </li>
                  <li>Logistique</li>
                  <li>Frêt</li>
                  <li>Livraison express</li>
                  <li>Demenagement</li>
                  <li>Services</li>
                  <li>Commissions</li>
                </ul>
            </div>
            <div id='s9adresse'> 
            <div className='s9adresse1'>
              <span id='adresse'>Adresse</span>
              <div id='adresseBz'>
                <span className='adresses'>Brazzaville</span>
                <div>
                  1249 Rue Vindza  - <br/>Plateau de 15  ans 
                </div>
              </div>
              {/*<div id='adressePn'>
                <span className='adresses'>Pointe-Noire</span>
                <div>
                  Quartier Mpaka -<br/> La balance
                </div>
  </div>*/}
            
            </div>
            <div className='s9adresse2'>
                <div id='addremail'>
                <span id='mail'>Email</span>
                <div>
                  Contact@ibsexpress.cg
                </div>
              </div>
              <div className="sept">
                <span id='ouverture'>7j/7 A votre Service</span>
              <div id='daysopened'>
                <span id='lescontacts'>
                +242 06 707 82 82 <br/> +242 05 399 89 37
                </span>
              </div>
              </div>
            </div>
              
              
            </div>
            <div id='s9news'> 
                
              <div>

                <iframe title='newsletters' data-w-type="embedded" scrolling='no' frameBorder="0"  marginHeight="0" marginWidth="0" src="https://x65pk.mjt.lu/wgt/x65pk/69v/form?c=48f11408" width="100%" style={{height: 350}}></iframe>
                  
                <script type="text/javascript" src="https://app.mailjet.com/pas-nc-embedded-v1.js"></script>
              </div>
                
            </div>
          </div>
      </div>  
      <div className=' footerbg'>
        <div id='footer'>
          <ul id='flist'>
            <li>© 2022-2023  ibsexpress.cg - Tous droits réservés. </li>
            <li onClick={props.Anchor}><NavLink to='./CGU' onClick={props.FooterTogfunc}>Mention legales (CGU et CGV)</NavLink></li>
            <li onClick={props.Anchor}><NavLink to='./DPC' onClick={props.FooterTogfunc}>Donnees personnelles et cookies</NavLink></li>
            <li><a href="https://ornoir.net">Créé par : Ornoir.net</a></li>
          </ul>
        </div>
      </div>
     
      
    </>
    
  )
}

export default Footer