import React, {useRef, useState } from 'react'
import './mini_devis.css'
import Button from '../button/button'
import { sendEmaildevisform } from '../../helpers/EmailJs';
import{mini_checker_Nom_Utils,mini_checker_Prenom_Utils,mini_checker_Tel_Utils,mini_checker_Mail_Utils,mini_checker_Message_Utils} from '../../helpers/Utils'




const Mini_devis =(props) => {
  const form = useRef();
  const Okpopup=props.okpopup
  const [Message_erreur, setMessage_erreur]=useState('')

  const [bool_nom,set_Bool_nom]=useState(false)
  const [bool_prenom,set_Bool_prenom]=useState(false)
  const [bool_tel,set_Bool_tel]=useState(false)
  const [bool_mail,set_Bool_mail]=useState(false)
  const [bool_msg,set_Bool_msg]=useState(false)
  const [check_box,set_check_box]=useState(false)

  const arrayed=[bool_nom,bool_prenom,bool_tel,bool_mail,bool_msg,check_box]
  const sendable=[true,true,true,true,true,true]
  
  const checker_Nom=(e)=>mini_checker_Nom_Utils(form,setMessage_erreur,set_Bool_nom)
  const checker_Prenom=(e)=>mini_checker_Prenom_Utils(form,setMessage_erreur,set_Bool_prenom)
  const checker_Tel=(e)=>mini_checker_Tel_Utils(form,setMessage_erreur,set_Bool_tel)
  const checker_Mail=(e)=>mini_checker_Mail_Utils(form,setMessage_erreur,set_Bool_mail)
  const checker_Message=(e)=>mini_checker_Message_Utils(e,setMessage_erreur,set_Bool_msg)

  function current() {
    setMessage_erreur(Message_erreur)

   }

function reset_bool () {
    set_Bool_nom(false);
    set_Bool_prenom(false)
    set_Bool_tel(false)
    set_Bool_mail(false)
    set_Bool_msg(false)
   }
  
function checker_check_box(e) {
    form.current.childNodes[5].classList.remove('agree')
    if(form.current.childNodes[5].childNodes[0].checked) {
       set_check_box(true) 
    }
    if(!form.current.childNodes[5].childNodes[0].checked) {
        set_check_box(false) 

    }
    
    

   }
 
/*
  function checker_Nom(){  
    if(Regex_Nom.test(form.current.childNodes[0].value)){setMessage_erreur('');form.current.childNodes[0].classList.replace('erreur', 'inputsmini')}
    else{setMessage_erreur('champ nom mal saisi');form.current.childNodes[0].classList.replace('inputsmini', 'erreur')}
}
function checker_Prenom(){  
    if(Regex_Prenom.test(form.current.childNodes[1].value)){setMessage_erreur('');form.current.childNodes[1].classList.replace('erreur', 'inputsmini')}

    else{setMessage_erreur('champ prénom mal saisi');form.current.childNodes[1].classList.replace('inputsmini', 'erreur')}
}
function checker_Tel(){ 
    if(Regex_Tel.test(form.current.childNodes[2].value)) {setMessage_erreur('');form.current.childNodes[2].classList.replace('erreur', 'inputsmini')}

    else {setMessage_erreur(' Champ tel mal saisi');form.current.childNodes[2].classList.replace('inputsmini', 'erreur')}
} 
function checker_Mail(){ 
    if(Regex_Mail.test(form.current.childNodes[3].value)) {setMessage_erreur('');form.current.childNodes[3].classList.replace('erreur', 'inputsmini')}

    else {setMessage_erreur('champ mail mal saisi');form.current.childNodes[3].classList.replace('inputsmini', 'erreur')}
}
function checker_Message(e){  
  if(e.target.value.length===0)
  {
     // setMessage_erreur(msg_erreur[0])
     e.target.classList.replace('inputsmini','erreur')
  }

  else
  {
      //setMessage_erreur(msg_erreur[0])
    e.target.classList.replace( 'erreur','inputsmini')
      
  }
}
*/

  const devis=(e)=>{
     if(arrayed.toString()===sendable.toString()) { 
      sendEmaildevisform('service_06hml2p','template_c4jhfk6',form.current,'Jj8wzkTC7vcdn7XoZ')
      Okpopup()
      form.current.reset()
      reset_bool()
      
    }
    else {
        checker_Nom(e)
        checker_Prenom(e)
        checker_Tel(e)
        checker_Mail(e)
        //checker_Message(e)
        if(form.current.childNodes[4].value.length<=0) {
            form.current.childNodes[4].classList.add('erreur');
        }
        if(form.current.childNodes[5].childNodes[0].checked) {
            form.current.childNodes[5].classList.remove('agree')
        }
        if(!form.current.childNodes[5].childNodes[0].checked){
            form.current.childNodes[5].classList.add('agree')

        }
      
      }
    
  }
 

  return (
    <>
    
    <div id='divformulaire_mini_devis' >
        <h1>Demandez un devis</h1>
        <form id='formulaire_mini_devis' ref={form} onFocus={current}>
            <input id="mini_Nom_devis" className='inputsmini' type="text" name='user_name' placeholder='Nom' onBlur={checker_Nom}/>
            <input id="mini_Prenom_devis" className='inputsmini' type="text" name='user_surname' placeholder='Prénom' onBlur={checker_Prenom}/>
            <input id="mini_tel_devis" className='inputsmini' type="phone" name='user_phone' placeholder='Tél' maxLength={14} onBlur={checker_Tel}/>
            <input id="mini_mail_devis" className='inputsmini' type="mail" name='user_email'  placeholder='Email' onBlur={checker_Mail}/>
            <textarea id="mini_msg_devis" className='inputsmini'  name="message" placeholder='Message'rows="4" cols="50" onBlur={checker_Message}></textarea>
            <div id='checkboxdivmini' onClick={checker_check_box}>
            <input type="checkbox" name="agree" id="checkboxfmini"/>                 
              <label htmlFor="checkboxfmini">
                  J'accepte la politique <br/>de confidentialité
              </label> 
            </div>
        <Button id='abobtnmini' text='Envoyez' onClick={devis}/>
        </form>
        
    </div>
    </>
  )
}

export default Mini_devis;