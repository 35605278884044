import React, { useState } from 'react'
import './DPC.css'
import MiniDevis from "../../../component/mini_devis/mini_devis";
import Confirmation from '../../../component/confirmation/Confirmation';

const CGD = () => {

  const [Hide_Ok,setHide_Ok]=useState('Hide')

  const okpopup=(e)=>{
    
    setHide_Ok('block')

  }
 

  const ok_hide=(e)=>{
    setHide_Ok('Hide')
    
  }
  return (
    <><div style={{position :'fixed',top:'20vh',left:'35vw'}}>
        <Confirmation Id={Hide_Ok} onClick={ok_hide}/>
    </div>
   
    
    <div id='DPC'>
      <div id='DPC_content'>
        <h1>Données personnelles et Cookies</h1>

        <h2 id='donp'>Données personnelles</h2>

        <h2 className='DPC_content_h2'>Recueil et traitement des données personnelles </h2>

        <div>
          <p>
          Interlogistique Batiment Services (IBS) s’engage à préserver la vie privée des utilisateurs de ses sites Web.Lorsque vous visitez nos sites, par mesure de sécurité nos serveurs Web effectuent toujours une sauvegarde temporaire des données de connexion de l’ordinateur qui accède à notre site, d’une liste des pages Web que vous parcourez sur notre site, de la date et de la durée de votre visite, des données d’identification du type de navigateur et de système d’exploitation utilisé, ainsi que du site Web qui vous a redirigé vers notre site. Aucune autre information personnelle (nom, adresse, numéro de téléphone ou adresse électronique) n’est recueillie, à moins que vous ne communiquiez ces informations volontairement (par ex., en remplissant un formulaire en ligne dans le cadre d'un enregistrement, d’un sondage, d’un concours, d’un contrat ou d’une demande de renseignements).
          </p>
          <p>
          Interlogistique Batiment Services(IBS) garantit un droit d’accès et de modification des données personnelles conformément à la législation en vigueur.
          </p>
          
          <p>
          Certaines informations relatives aux envois seront communiquées aux autorités du pays de transit ou de destination,à des fins de dédouanement, d’exonération fiscale ou de contrôle de sécurité, conformément à la législation de ce pays.Les informations fournies comprennent généralement : nom et adresse de l’expéditeur, nom et adresse du destinataire,description des marchandises, nombre d’éléments, poids et valeur du colis.
          </p>

        </div>

        <h2 className='DPC_content_h2'>Utilisation et transfert des données personnelles</h2>
        
        <div>
          <p>
          Nous utilisons les données personnelles que vous nous communiquez exclusivement dans le cadre de l’administration technique des pages Web et afin de répondre à vos requêtes.Il s’agit essentiellement de remplir un contrat conclu avec vous ou de répondre à votre requête. En retour, elles nous permettent d’améliorer les services que nous vous offrons,de faciliter l’utilisation de notre site Web et de personnaliser son contenu et ses services. 
          </p>
          <p>
          Ce n’est qu’avec votre accord préalable ou, si la réglementation juridique le stipule,si vous n'avez pas émis d'objection que nous utilisons également ces données dans le cadre de sondages sur des produits et à des fins commerciales.
          </p>
          
          <p>
          Interlogistique Batiment Services (IBS) s’engage à ne pas partager, vendre, transférer ni diffuser autrement vos données personnelles à des tiers et ne le fera pas ultérieurement, sauf obligation légale,obligation contractuelle ou consentement explicite de votre part.Par exemple, il se peut que nous devions communiquer votre adresse et les informations relatives à votre commande à nos prestataires lorsque vous commandez des produits.
          </p>

        </div>

        <h2 className='DPC_content_h2'>Informations diffusées par Interlogistique Batiment Services (IBS).</h2>
        
        <div>
          <p>
          Interlogistique Batiment Services (IBS) voudrait vous contacter afin de vous tenir informé des mises à jour de notre site Web,ou de nos offres, actualités, produits et services. Si vous prenez contact ou vous enregistrez avec Interlogistique Batiment Services (IBS) sur nos pages Web,nous vous demandons parfois d'indiquer si vous souhaitez recevoir nos campagnes d’informations. Si vous êtes déjà un client de Interlogistique Batiment Services (IBS),vous continuerez naturellement à recevoir des informations essentielles sur des changements importants apportés aux contrats en cours (par ex., révisions de tarifs).
          </p>
        </div>

        <h2 className='DPC_content_h2'>Utilisation du suivi Web</h2>
        
        <div>
          <p>
          Nous utilisons un logiciel de suivi pour déterminer combien d'utilisateurs ont visité notre site Web et à quelle fréquence.Nous n’utilisons pas ce logiciel pour recueillir des données personnelles ou des adresses IP individuelles.Les données sont utilisées exclusivement de façon anonyme et concise, à des fins statistiques et de développement du site Web.
          </p>
        </div>

        <h2 className='DPC_content_h2'>Utilisation de cookies</h2>
        <div>
          <p>
          Les « cookies » sont de petits fichiers qui nous permettent de stocker des informations spécifiques à l’utilisateur,à vous, et à votre ordinateur, pendant que vous visitez l’un de nos sites Web.Les cookies nous permettent de déterminer la fréquence à laquelle nos pages Web sont parcourues et le nombre d’utilisateurs.Par ailleurs, ils nous aident à configurer nos offres afin d’optimiser leur utilité et leur efficacité.
          </p>

          <p>
          D’une part, nous utilisons des « cookies temporaires ».Ceux-ci sont stockés exclusivement pour la durée de votre visite de l’une de nos pages Internet.D’autre part, nous utilisons des « cookies persistants », qui conservent les informations relatives aux utilisateurs qui ont accédé à plusieurs reprises à l’une de nos pages Internet.L’utilisation de cookies vise à optimiser les conseils que nous vous offrons et à vous identifier lors de votre visite;nous sommes ainsi en mesure de varier autant que possible nos pages Internet et d’en renouveler le contenu lors d’un usage répété.
          </p>
          
          <p>
          En règle générale, nous ne créons pas de profil individuel de vos activités en ligne. Le contenu d’un cookie persistant se restreint à un numéro d’identification.Le nom, l’adresse électronique, l’adresse IP, etc. ne sont pas sauvegardés sur la majorité de nos sites.
          </p>

          <p>
          Il y a une exception à cela, il s’agit des Cookies Google Analytics :
          </p>
          <p>
          Ces cookies utilisent votre adresse IP pour vous reconnaître, mais ne vous identifient pas individuellement.En d’autres termes, les informations sont recueillies de façon anonyme.Les cookies recueillent des informations sur la façon dont les visiteurs utilisent nos sites locaux et s’en servent pour créer des rapports et nous aider à améliorer ces sites.
          </p>

        </div>

      </div>
      <MiniDevis okpopup={okpopup} />
    </div>

    
    </>
  )
}

export default CGD