import React from 'react';
import './nav.css';
import logo from '../../assets/logo-ibs.png';
import hamburger from '../../assets/mobile/hamburger.png'
import home from '../../assets/mobile/home.png'
import service from '../../assets/mobile/service.png'
import gal from '../../assets/mobile/Galerie.png'
import about from '../../assets/mobile/Apropos.png'
import phone_ico from '../../assets/mobile/phone_ico.png'
import {NavLink} from 'react-router-dom';




const Nav = (props) => {
    
    
  return (
    <>
        <div id="Navbar" onClick={props.Anchor}>
            <div id='logo'>
                <a href="./">
                    <img src={logo} alt="Logo" />
                </a>
            </div>
            <ul>
                
                <li>
                    <NavLink to='./' style={({isActive})=>{return isActive?{color: 'white', backgroundColor:'#003393',fontWeight: 700,transition: '0.3s'}:{}}}>Accueil</NavLink>
                </li>
                <li>
                    <NavLink to='./Service' style={({isActive})=>{return isActive?{color: 'white', backgroundColor:'#003393',fontWeight: 700,transition: '0.3s'}:{}}}>Service</NavLink>
                </li>
                <li>
                    <NavLink to='./Galerie' style={({isActive})=>{return isActive?{color: 'white', backgroundColor:'#003393',fontWeight: 700,transition: '0.3s'}:{}}}>Galerie</NavLink>
                </li>
                <li>
                    <NavLink to='./About' style={({isActive})=>{return isActive?{color: 'white', backgroundColor:'#003393',fontWeight: 700,transition: '0.3s'}:{}}}> A propos de nous</NavLink>
                </li>
            </ul>
            <div id='contacts'>
               
                <div id='nos_nums'>
                    <a href="tel:+242 06 707 82 82">06 707 82 82</a>
                    <a href="tel:+242 05 399 89 37">05 399 89 37</a>
                </div>
                <div id='dispo'>7j/7 à votre service</div>
    
                
            </div>
        </div>

        
        <div id="Navbarmobile">
            <div id='logo'>
                <a href="./">
                        <img src={logo} alt="" />
                </a>
            </div>
            <div id='mobilenav'>
                    <div id='ham' onClick={(e)=>e.currentTarget.parentNode.nextSibling.classList.toggle('hide')}><img src={hamburger} alt="hamburger" /></div>
                    
            </div> 
            <div className='menuderoulant hide' onClick={props.Anchor}>
                        
                        <ul>
                            <li onClick={(e)=>e.currentTarget.parentNode.parentNode.classList.toggle('hide')}><NavLink to='./' style={({isActive})=>{return isActive?{color: 'white', fontSize:'1rem',fontWeight: 700,transition: '0.3s'}:{}}}><img src={home} alt="home_ico"/> Acceuil</NavLink></li>
                            <li onClick={(e)=>e.currentTarget.parentNode.parentNode.classList.toggle('hide')}><NavLink to='./Service' style={({isActive})=>{return isActive?{color: 'white',fontSize:'1rem',fontWeight: 700,transition: '0.3s'}:{}}}><img src={service} alt="services"/>Services</NavLink></li>
                            <li onClick={(e)=>e.currentTarget.parentNode.parentNode.classList.toggle('hide')}><NavLink to='./Galerie' style={({isActive})=>{return isActive?{color: 'white',fontSize:'1rem',fontWeight: 700,transition: '0.3s'}:{}}}><img src={gal} alt="gal"/>Galerie</NavLink></li>
                            <li onClick={(e)=>e.currentTarget.parentNode.parentNode.classList.toggle('hide')}><NavLink to='./About' style={({isActive})=>{return isActive?{color: 'white',fontSize:'1rem',fontWeight: 700,transition: '0.3s'}:{}}}><img src={about} alt="about_ico"/>à propos de nous</NavLink></li>
                        </ul>
                        <div id='bloc_call' >
                            <a href="tel:+242 06 707 82 82" id='numb_to_call'>
                                <span id='zip'><img src={phone_ico} alt="call" /> +242 </span>
                                <span id='central_part'>06 707 82 82</span>
                            </a>
                            <a href="tel:+242 05 399 89 37" id='numb_to_call'>
                                <span id='zip'><img src={phone_ico} alt="call" /> +242 </span>
                                <span id='central_part'>05 399 89 37</span>
                            </a>
                            <span >7j/7 A  Votre service</span>
                        </div>
                        
            </div> 
                
        </div>
        
    </>
    
  )
}

export default Nav