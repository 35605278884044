import React from 'react'
import './Service.css'
import donneeimg from '../../databaseimg'
import Button from '../../component/button/button'
import { useState } from 'react';
import PopupDevis from '../../component/popup_devis/popup_devis'
import Slideshow from '../../component/slide/slide'
import call_ico from '../../assets/call_ico.png'
//import parc from '../../assets/parc.png'


const Service = () => {
 
  
  const [Hide,setHide]=useState('Hide')

  function HidePopup_Devis(e) {
    
    setHide('Hide')
      
  }

  function ShowPopup_Devis(e) {
  
  setHide('Hidepas')
         
  }

  return (
    <>
    <div id={Hide} style={{position:'fixed',width:'100%', height:'100%',zIndex:98}}>
    <div id='popup' style={{display:'flex',alignContent:'center',justifyContent:'center',transition: '0.3s',position:'relative'}}>
      <PopupDevis fermeture={HidePopup_Devis}/>
    </div>
  </div>
    <div id='Service'>
      <div id='Servicesection1slide'> 
        <Slideshow/>
      </div>
      <div className='Centercontent'>
        <div id='Servicesection1'>
      
          <div id='Servicesection1item1'>
            <img src={donneeimg.camion} alt="camion"/>
          </div>
      
          <div id='Servicesection1item2'> 
            <h1>
              Transporter vos colis<br/> et courriers sur le <br/>territoire congolais <br/>en toute sécurité
            </h1>
            <div id='barresurligne'></div>
            <p>
            Peu importe où vous souhaitez envoyer votre colis, nous le livrerons à domicile. Nous avons construit un vaste réseau de transporteurs pour vous garantir une livraison Express de 24 à 72h partout sur le territoire congolais.
            </p>
            <div id='Servicesection1item2div'>
              <div>
                <Button id='ss2' text='Demander un devis' onClick={ShowPopup_Devis} />
              </div>  
            </div>
          </div>
        </div>
        
      </div>
      <div id="locationbg">
        <div id='Location' className='Centercontent'>
  
      <div id='textelocation'> 
        <h1>
          Louer vos véhicules <br /> en fonction de vos besoins 
        </h1>
        <p>
        Peu importe où vous souhaitez transporter vos marchandises ou matériels. Nous mettons à votre disposition un vaste parc automobile constitué : de vehicules de luxes, de camions plateaux, de bus et de camions Hiab pour repondre à toutes vos sollicitations 24h/24 partout sur le territoire congolais.
        </p>
        <div id='buttonlocation'>
          <div>
            <Button id='ss2' text='Demander un devis' onClick={ShowPopup_Devis} />
          </div>  
        </div>
      </div>
      
         </div>
      </div>
    
      
      <div id='Servicesection2'>

      </div>
      <div>

        <div id='Servicesection3'>
          <div id='Servicesection3flex' className="Centercontent">
            <div>
              Chine - Congo <br/>
              Turquie - Congo <br/>
              Dubaï - Congo <br/>
              et dans la sous-région<br/>
              d’Afrique Centrale <br/>
              nous assurons pour vous <br/>
              un fret aérien, maritime <br/>
              ou terrestre.
            </div>
            <div><img src={donneeimg.avion} alt="Avion decollant" /></div>
          </div>
        </div>
      
      
        <div id='Servicesection4' >

          <div className="Centercontent">
            <span id='ws'>Work Steps</span>
            <span id='ctn'>Comment travaillons nous ?</span>
          </div>
          <div id="Servicesection4div">
            <img src={donneeimg.workstep} alt="etapes"  />  
          </div>
          
        
        </div>

        <div id='Servicesection5' className='Centercontent'>
          <div>
            <p>
              Du conseil jusqu'aux <br/>
              services intégrés, nous <br/>
              ajoutons de la valeur<br/> à
              votre chaîne logistique.
            </p>
            <p>
            Nous identifions et optimisons <br/>
            les opportunités<br/> de votre 
            chaîne logistique.
            </p>
          </div>
          <img src={donneeimg.left} alt="ouvrier croisant les bras" />
        </div>

        <div id='Servicesection6' className='Centercontent'>
          <img src={donneeimg.right} alt="Enfants jouants" />
          <div>
            <p>
            Pour votre déménagement <br/>personnel, d'entreprise <br/>et/ou d'entrepôt, nous garantissons <br/>un service fiable<br/> et sécurisé de vos biens.
            </p>
          </div>
        </div>

        <div id='Servicesection7' className='Centercontent'>
      
          <div id='Servicesection7text'>
            <span className='ord1'>Plus de soucis !</span>
            <img src={donneeimg.titremascotte} className='ord2' alt="titre petit commisionaire" />
            <span className='ord3'>Rend service en express </span>
            <p>
            Le petit commissionaire est le coup de pouce dont vous avez besoin pour  soulager votre emploi du temps. Contournez la difficulté de la disponibilité à l’echelle nationale en vous appuyant sur notre service de commission pour : Des courses ordinaires avec livraison (marché, magasins, pharmacie, vente en ligne, restaurant, etc), des missions diverses et personnalisées (cadeaux, surprises etc)  l’organisation d’événements (anniversaire, mariage etc).

            </p>
            <div id='callcommision'>
              <a style={{textDecoration:'none',display:'flex', justifyContent:'center',alignContent:'center'}}href="tel:+242 06 707 82 82">
              <div className="buttondevis"><img style={{width:'18%',marginRight:'0.3rem'}} src={call_ico} alt="call" /> 06 707 82 82</div>
              </a>
              <a style={{textDecoration:'none',display:'flex', justifyContent:'center',alignContent:'center'}}href="tel:+242 05 399 89 37">
             <div className="buttondevis"><img style={{width:'18%',marginRight:'0.3rem'}} src={call_ico} alt="call" /> 05 399 89 37</div>
              </a>
            </div>
            
            
            
          </div>
          <div id='Servicesection7commissionaire' className='ord4'>
            <div id='bg_com'>
            </div>
            <img src={donneeimg.mascotte} alt="le petit commisionaire" />
          </div>
    
        </div>
      </div>
    </div>
    </>
  )
}

export default Service