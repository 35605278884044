import React, { useState,useRef,useEffect } from 'react'
import './popup_devis.css'
import 'intl-tel-input/build/css/intlTelInput.css'
import logogram from '../../assets/logogram.png'
import { sendEmaildevisform } from '../../helpers/EmailJs';
import{checker_Nom_Utils,checker_Prenom_Utils,checker_Tel_Utils,checker_Mail_Utils,checker_Message_Utils} from '../../helpers/Utils'
import Confirmation from '../confirmation/Confirmation';

import {Pays, allCountries, allpays} from '../../Pays'

/*import intlTelInput from 'intl-tel-input';*/
import intlTelInput  from '../../../node_modules/intl-tel-input/build/js/intlTelInput';

import Utilitaire from '../../../node_modules/intl-tel-input/build/js/utils.js'


/*const input = document.querySelector("#phone");
intlTelInput(input, {
    utilsScript: Utilitaire
});*/



 


const Popup_devis = (props) => {
    
   
    /* 
    useEffect(() => {
       intlTelInput(input.current, {
            utilsScript: Utilitaire
        })
        
        },[])


useEffect(()=>{const inputchamp = document.querySelector("#popup_tel_devis");
intlTelInput(inputchamp, {
    utilsScript: Utilitaire
});
},[])*/




    
             
    const form = useRef();

    const [Hide_Ok,setHide_Ok]=useState('Hide')
    const [Hide_popup,setHide_popup]=useState('divformulaire_popup_devis')
    const [Message_erreur, setMessage_erreur]=useState('')

    const [bool_nom,set_Bool_nom]=useState(false)
    const [bool_prenom,set_Bool_prenom]=useState(false)
    const [bool_tel,set_Bool_tel]=useState(false)
    const [bool_mail,set_Bool_mail]=useState(false)
    const [bool_msg,set_Bool_msg]=useState(false)
    const [check_box,set_check_box]=useState(false)

    const arrayed=[bool_nom,bool_prenom,bool_tel,bool_mail,bool_msg,check_box]
    const sendable=[true,true,true,true,true,true]


   const checker_Nom=(e)=>checker_Nom_Utils(form,setMessage_erreur,set_Bool_nom)
   const checker_Prenom=(e)=>checker_Prenom_Utils(form,setMessage_erreur,set_Bool_prenom)
   const checker_Tel=(e)=>checker_Tel_Utils(form,setMessage_erreur,set_Bool_tel)
   const checker_Mail=(e)=>checker_Mail_Utils(form,setMessage_erreur,set_Bool_mail)
   const checker_Message=(e)=>checker_Message_Utils(e,setMessage_erreur,set_Bool_msg)

   function current(e) {
    setMessage_erreur(Message_erreur)

   }

   function reset_bool () {
    set_Bool_nom(false);
    set_Bool_prenom(false)
    set_Bool_tel(false)
    set_Bool_mail(false)
    set_Bool_msg(false)
   }

   function checker_check_box(e) {
    form.current.nextSibling.classList.remove('agree')
    if(form.current.nextSibling.childNodes[0].checked) {
       set_check_box(true) 
    }
    if(!form.current.nextSibling.childNodes[0].checked) {
        set_check_box(false) 

    }
    
    

   }

/* Fonctions old    
function checker_Nom(){  
    if(Regex_Nom.test(form.current.childNodes[0].value))
    {   
      
        form.current.childNodes[0].classList.remove('failed')
      
    }
    else
    {   

        setMessage_erreur(erreurs.nom_erreur);
        form.current.childNodes[0].classList.add('failed')
        
    }
}
function checker_Prenom(){  
    if(Regex_Prenom.test(form.current.childNodes[1].value))
    {
    
        form.current.childNodes[1].classList.remove('failed')
        
    }

    else
    {
      
        setMessage_erreur(erreurs.prenom_erreur);
        form.current.childNodes[1].classList.add('failed')
        
    }
}
function checker_Tel(){ 
    if(Regex_Tel.test(form.current.childNodes[2].value)) 
    {

        form.current.childNodes[2].classList.remove('failed')
        
    }

    else 
    {

        setMessage_erreur(erreurs.numero_erreur);
        form.current.childNodes[2].classList.add('failed')
        
    }
} 
function checker_Mail(){ 
    if(Regex_Mail.test(form.current.childNodes[3].value)) 
    {
        
        form.current.childNodes[3].classList.remove('failed')
        
    }

    else 
    {
      
        setMessage_erreur(erreurs.mail_erreur);
        form.current.childNodes[3].classList.add('failed')
        
    }
}
function checker_Message(e){  
        if(e.target.value.length===0)
        {
        
           e.target.classList.add('failed')
        }

        else
        {
            
            setMessage_erreur(erreurs.mail_erreur);
          e.target.classList.remove('failed')
            
        }
}
*/

const devis=(e)=>{
    if(arrayed.toString()===sendable.toString()) { 
    sendEmaildevisform('service_06hml2p','template_c4jhfk6',form.current,'Jj8wzkTC7vcdn7XoZ')
    setHide_Ok('block')
    setHide_popup('Hide')
    form.current.reset()
    
    reset_bool()
    }
    else {
        checker_Nom(e)
        checker_Prenom(e)
        checker_Tel(e)
        checker_Mail(e)
        //checker_Message(e)
        if(form.current.childNodes[4].value.length<=0) {
            form.current.childNodes[4].classList.add('failed');
        }
        if(form.current.nextSibling.childNodes[0].checked) {
            form.current.nextSibling.classList.remove('agree')
        }
        if(!form.current.nextSibling.childNodes[0].checked){
            form.current.nextSibling.classList.add('agree')

        }
        

    }
    
        
}

const ok=(e)=>{
        setHide_Ok('Hide')
        setHide_popup('divformulaire_popup_devis')
        props.fermeture()
}



  return (
    <>  <Confirmation Id={Hide_Ok} onClick={ok}/>
        <div id={Hide_popup}>
            <div id='fermeture' onClick={props.fermeture}>
                X
            </div>

            <div id='leftside'>
                <div id='logogram'>
                 <img src={logogram} alt="Logogram" />
                </div>
                <p>
                    Interlogistique <br/>
                    Batiment <br/>
                    Services
                </p>

                <span className='un'>06 707 82 82 <br /> 05 399 89 37</span>
                <span className='deux'>7 Jours / 7 à  votre service</span>
    
            </div>
            
            <div id='rightside'>
                <div id='rightsidediv' >
                      <h1>Demandez un devis </h1>
        
                    <form id='formulaire_popup_devis' ref={form} onBlur={current}>
                        <input id="popup_Nom_devis" type="text" name="user_name" placeholder='Nom' onBlur={checker_Nom} />
                        <input id="." type="text" name="user_surname" placeholder='Prénom' onBlur={checker_Prenom} />
                        <input id="popup_tele_devis" type="phone" name="user_phone" placeholder='Tél : +000 00000...' maxLength={14} onBlur={checker_Tel}/>

                       {/*<input id="popup_tel_devis" type="tel" name="user_phone" placeholder='Tél' maxLength={10} onBlur={e=>alert(intlTelInput(e.currentTarget, {
            utilsScript: Utilitaire
        }).getNumber())}/>
                        <select id='selecteur' style={{height:'64px',width:'100px', borderRadius:'32px',textAlign:'center',alignSelf:'flex-start',marginTop:'32px'}}>
                                {Pays.map((countrie,index)=>(<option key={index} value={countrie} onClick={e=>alert(e.currentTarget.value)}>{countrie[0]}</option>))}

                           </select>*/
                        }
                        {/*<div style={{position:'relative',display:'flex',alignItems:'center',justifyContent:'center',}}>

                            <div  style={{position:'absolute', bottom:'1.5vh', right:'1.5vh',width:'25px', alignItems:'center',display:'flex',justifyItems:'center',borderLeft:'2px solid black',paddingLeft:'0.1rem'}} onClick={(e)=>{alert(e.currentTarget)}}>
                                <div id='flaggie' style={{alignItems:'center'}}></div>
                            </div>
                            <input id="popup_tele_devis" type="phone" name="user_phone" placeholder='Tél' maxLength={14} onBlur={checker_Tel}/>
                            </div>
                        */}

                        <input id="popup_mail_devis" type="email" name="user_email" placeholder='Email' onBlur={checker_Mail}/>
                        <textarea id="popup_msg_devis" name="message" placeholder='Message'rows="4" cols="50"  onBlur={checker_Message} ></textarea>
                    </form>
                    
                    <div id='checkboxdivpopup'onClick={checker_check_box}>
                            <input type="checkbox" id="checkboxfpopup"/>                 
                            <label htmlFor="checkboxfpopup">
                            J'accepte la politique de confidentialité
                            </label> 
                    </div>
                    <div id='abobtnpop' onClick={devis}>Envoyez</div>
                    
                </div>
            
            </div>  
        </div>
     
    </>
    
    
  )
}


export default Popup_devis