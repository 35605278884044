import React from 'react'
import { BrowserRouter, Routes,Route } from 'react-router-dom';
import './index.css';
import Nav from './part/nav/nav';
import NavPol from './part/Navpol/Navpol'
import Home from './pages/Home/Home';
import Service from './pages/Service/Service'
import Galerie from './pages/Galerie/Galerie'
import About from './pages/About/About'
import Footer from './part/footer/footer';
import CGU from './pages/Politiques/CGU/CGU'
import DPC from './pages/Politiques/DPC/DPC'
import ML from './pages/Politiques/ML/ML'
import { useState } from 'react';
import Articles from './pages/Articles/Articles';
import ArticleHelios from './pages/Helios/Articles';

const App = () => {
    const [showNavbar, setshowNavbar]=useState(true)
    
    function TogOn() {

        setshowNavbar(true)
        
    }
    function TogOff() {

        setshowNavbar(false)
        
        
    }

    function topAnchor () {
        document.getElementById('Top_page').scrollIntoView({behavior:'smooth'})
    }

  return (
    <>
        <BrowserRouter>
            <div id='Top_page'></div>
            {showNavbar && <Nav Anchor={topAnchor}/>}
            {!showNavbar && <NavPol Anchor={topAnchor} NavTogfunc={TogOn}/>} 
            
            <Routes>  
            <Route path='/' element={<Home/>}/>
            <Route path='/Service' element={<Service/>}/>
            <Route path='/Galerie' element={<Galerie/>}/>
            <Route path='/About' element={<About/>}/>     
            <Route path='/CGU' element={<CGU/>}/>
            <Route path='/DPC' element={<DPC/>}/>
            <Route path='/ML' element={<ML/>}/>
            <Route path='/Articles' element={<Articles onLoad={topAnchor}/>}/>
            <Route path='/ArticleHelios' element={<ArticleHelios onLoad={topAnchor}/>}/>
            </Routes>

            <Footer Anchor={topAnchor} FooterTogfunc={TogOff}/>
        </BrowserRouter>
    </>
    
  )
  
}

export default App