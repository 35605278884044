import React from 'react'
import './Membre.css'

const Membre = (props) => {
  return (
    <div id='membre'>
        <img src={props.profil} alt="profil" />
        <span>{props.poste}</span>
        <p>
            {props.noms }
        </p>
    </div>
  )
}

export default Membre